import { OPERATOR_TYPE } from '@features/filters/types';
import i18n from '@features/i18n';
import { type ITargetGroup } from '@modules/automaticCampaigns/types';

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  trigger: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  enabled: { value: false, inversion: true, operator: OPERATOR_TYPE.EQUAL },
  betActionType: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  bonuses: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
};

export const targetGroup: ITargetGroup = {
  SEGMENT: i18n.t('campaign.automatic.grid.modal.target.segment'),
  OPEN_FOR_ALL: i18n.t('campaign.automatic.grid.modal.target.all'),
};
