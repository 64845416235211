import { Box, Container, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants';
import { openSidebar } from '@features/menu/menuSlice';
import {
  useActiveItemUi,
  useAppDispatch,
  useAppSelector,
  useCatalog,
} from '@hooks';
import { Header } from '@layout/MainLayout/Header';
import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';
import { Sidebar } from '@layout/MainLayout/Sidebar';

const MainLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const appConfig = useAppSelector((state) => state.appConfig.config);
  const dispatch = useAppDispatch();

  const isIframe = window.self !== window.top;

  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const isSidebarOpen =
    useAppSelector((state) => state.menu.isSidebarOpen) ?? false;

  const [isOpen, setIsOpen] = useState(isSidebarOpen);

  useCatalog();

  useActiveItemUi();

  const isRoot = useMatch(ROUTES.ROOT);

  useEffect(() => {
    if (isRoot) {
      navigate(ROUTES.BONUS_MANAGEMENT.ROOT);
    }
  }, [isRoot, navigate]);

  useEffect(() => {
    setIsOpen(!matchDownLG);
    dispatch(openSidebar({ isSidebarOpen: !matchDownLG }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (isOpen !== isSidebarOpen) {
      setIsOpen(isSidebarOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarOpen]);

  const handleSidebarToggle = () => {
    setIsOpen(!isOpen);
    dispatch(openSidebar({ isSidebarOpen: !isOpen }));
  };

  const {
    layoutWrapperStyles,
    outletContainerStyles,
    layoutContentWrapperStyles,
  } = useModuleStyles();

  return (
    <Box sx={layoutWrapperStyles}>
      {!isIframe ? (
        <>
          <Sidebar
            handleSidebarToggle={handleSidebarToggle}
            isSideBarOpen={isOpen}
          />
          <Header
            handleSidebarToggle={handleSidebarToggle}
            isIframe={isIframe}
            isSidebarOpen={isOpen}
          />
        </>
      ) : null}
      <Box component="main" sx={layoutContentWrapperStyles}>
        {!isIframe ? <Toolbar /> : null}
        <Container
          maxWidth={appConfig.container ? 'xl' : false}
          sx={outletContainerStyles}
        >
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
