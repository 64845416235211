import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type FC, useMemo } from 'react';

import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';
import { type ISidebar, MiniSideBarStyled } from '@layout/MainLayout/Sidebar';
import SidebarHeader from '@layout/MainLayout/Sidebar//SidebarHeader';
import SidebarContent from '@layout/MainLayout/Sidebar/SidebarContent';

export const Sidebar: FC<ISidebar> = ({
  window,
  isSideBarOpen,
  handleSidebarToggle,
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const sidebarContent = useMemo(() => <SidebarContent />, []);
  const sidebarHeader = useMemo(
    () => <SidebarHeader open={isSideBarOpen} />,
    [isSideBarOpen]
  );

  const { drawerStyles } = useModuleStyles();

  return (
    <Box
      aria-label="mailbox folders"
      component="nav"
      sx={{ zIndex: 1200, flexShrink: { md: 0 } }}
    >
      {!matchDownMD ? (
        <MiniSideBarStyled
          data-testid="data-sidebar"
          open={isSideBarOpen}
          variant="permanent"
        >
          {sidebarHeader}
          {sidebarContent}
        </MiniSideBarStyled>
      ) : (
        <Drawer
          container={container}
          data-testid="data-sidebar"
          ModalProps={{ keepMounted: true }}
          onClose={handleSidebarToggle}
          open={isSideBarOpen}
          sx={drawerStyles}
          variant="temporary"
        >
          {sidebarHeader}
          {sidebarContent}
        </Drawer>
      )}
    </Box>
  );
};
